.bp3-tooltip {
  .bp3-popover-content {
    background-color: white;
    color: var(--main-black-2);
  }

  .bp3-popover-arrow-fill {
    fill: white;
  }
}
