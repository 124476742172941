$pt-intent-primary: #343f4b;

@import '~@blueprintjs/core/src/blueprint.scss';

.bp3-breadcrumb-current {
  color: var(--main-green-1);
}

.bp3-button.bp3-intent-primary {
  background-color: var(--main-green-1);
  color: white;
}

.bp3-tabs {
  margin-top: 2em;
  .bp3-tab-indicator {
    background-color: var(--main-green-1);
    height: 1px !important;
  }
}

.bp3-tab-list {
  width: fit-content;
  border-bottom: var(--main-green-1) 2px solid;
  .bp3-tab {
    padding: 10px 40px;
    font-weight: var(--bold);
    color: #969faa;
    &:focus {
      outline: none;
    }
    &:not([aria-disabled='true']):hover {
      color: black;
    }
    &[aria-selected='true'] {
      color: white !important;
      background-color: var(--main-green-1) !important;
    }
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s ease-in-out infinite;
  -moz-animation: rotating 1s ease-in-out infinite;
  -ms-animation: rotating 1s ease-in-out infinite;
  -o-animation: rotating 1s ease-in-out infinite;
  animation: rotating 1s ease-in-out infinite;
}
