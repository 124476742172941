.button-text-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.button-title {
  font-size: 13px;
  font-weight: 600;
}

.button-subtitle {
  overflow: hidden;
  font-size: 11px;
  font-weight: 500;
}
