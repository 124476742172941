.AiosSuggestMenu {
  .bp3-menu-item:hover {
    background-color: var(--main-light-green-1) !important;
  }
  .bp3-menu-item.bp3-intent-primary:hover,
  .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item,
  .bp3-menu-item.bp3-intent-primary.bp3-active {
    background-color: var(--main-green-1) !important;
    color: white !important;
  }
}
